import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { getConfig } from 'core/helpers';
import { withInfo } from 'core/hocs';

import './select.scss';
import { Select } from './select';

const ALLOWED_COUNTRIES = getConfig('ALLOWED_COUNTRIES');

const CountrySelectUI = (props) => {
  const { options, countryInfo, intl: { formatMessage } } = props;
  const filteredOptions = options.filter(country =>
    ALLOWED_COUNTRIES.includes(country.value));
  filteredOptions.push({ value: countryInfo?.iso2Code, label: formatMessage({ id: 'profile.country.rest-of-the-world' }) });

  const filteredProps = {
    ...props,
    options: filteredOptions,
  };

  return (
    <Select
      {...filteredProps}
    />
  );
};

CountrySelectUI.propTypes = {
  props: PropTypes.shape().isRequired,
  countryInfo: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()),
  intl: intlShape.isRequired,
};

CountrySelectUI.defaultProps = {
  options: [],
};

export const CountrySelect = injectIntl(withInfo(CountrySelectUI));
