import React from 'react';
import PropTypes from 'prop-types';

export const IconInPlayEncircled = ({ className }) => (
  <svg className={className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#icon-in-play-encircled)">
      <circle opacity="0.15" cx="18" cy="18" r="18" fill="white" />
      <path d="M14 25H22V27H14V25Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10C8.89543 10 8 10.8954 8 12V20C8 21.1046 8.89543 22 10 22H26C27.1046 22 28 21.1046 28 20V12C28 10.8954 27.1046 10 26 10H10ZM15.75 19.4641L21 16L15.75 12.5359V19.4641Z"
        fill="white"
      />
    </g>
  </svg>
);

IconInPlayEncircled.propTypes = {
  className: PropTypes.string,
};

IconInPlayEncircled.defaultProps = {
  className: null,
};
