import { IconSlotMachine } from 'components/icons/icon-slot-machine/icon-slot-machine';
// import { IconTournaments } from 'components/icons/icon-tournaments/icon-tournaments';
import { IconPromo } from 'components/icons/icon-promo/icon-promo';
// import { IconSlots } from 'components/icons/icons-header/icon-slots';
import { IconMiniGames } from 'components/icons/icon-mini-games/icon-mini-games';
// import { IconVip } from 'components/icons/icons-vip/icon-vip';
import { IconLiveCasinoEncircled } from 'components/icons/icon-live-casino-encircled/icon-live-casino-encircled';
import { IconSportsEncircled } from 'components/icons/icon-sports-encircled/icon-sports-encircled';
import { IconInPlayEncircled } from 'components/icons/icon-in-play-encircled/icon-in-play-encircled';
import { PAGE_NAMES } from '../../constants';

const {
  GAMES,
  PROMOS,
  // TOURNAMENT,
  SPORTSBOOK,
  // TOURNAMENTS,
  // VIP,
  IN_PLAY,
  MINI_GAMES,
  CATEGORY,
} = PAGE_NAMES;

export const menuItems = [
  {
    id: 'header.games',
    to: '/games',
    Icon: IconSlotMachine,
    pages: [GAMES, `${GAMES}/all`, `${GAMES}/slot`, `${GAMES}/roulette`, `${GAMES}/card`, `${GAMES}/sport`],
  },
  {
    id: 'header.live',
    to: '/games/live',
    Icon: IconLiveCasinoEncircled,
    pages: [`${GAMES}/live`],
  },
  {
    id: 'header.sports',
    to: '/sportsbook',
    Icon: IconSportsEncircled,
    pages: [SPORTSBOOK, IN_PLAY],
    classNameHeader: 'ml-auto',
  },
  {
    id: 'header.in-play',
    to: '/in-play?page=/live',
    Icon: IconInPlayEncircled,
    pages: [`/${SPORTSBOOK}?page=/live`, `/${IN_PLAY}?page=/live`],
  },
  {
    id: 'header.mini-games',
    to: `/${CATEGORY}/${MINI_GAMES}`,
    Icon: IconMiniGames,
    pages: [MINI_GAMES],
  },
  // {
  //   id: 'header.slots',
  //   to: '/games/slot',
  //   Icon: IconSlots,
  //   pages: [`${GAMES}/slot`],
  // },
  {
    id: 'header.promos',
    to: '/promos',
    Icon: IconPromo,
    pages: [PROMOS],
  },
  // {
  //   id: 'header.vip',
  //   to: '/vip',
  //   Icon: IconVip,
  //   pages: [VIP],
  // },
  // {
  //   id: 'header.tournaments',
  //   to: '/tournaments',
  //   Icon: IconTournaments,
  //   pages: [TOURNAMENTS, TOURNAMENT],
  //   classNameSidebar: 'ml-n0_25',
  //   classNameHeaderLast: 'mr-auto',
  //   isDropdown: true,
  // },
];
